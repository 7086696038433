import "./ProcessNew.css"

import process1 from "../../../Assets/Process/1.mp4"
import process2 from "../../../Assets/Process/2.mp4"
import process3 from "../../../Assets/Process/3.mp4"
import process4 from "../../../Assets/Process/4.mp4"
import process5 from "../../../Assets/Process/5.mp4"

const process = [
    {
        id: 1,
        title: "Planning and Requirement Analysis",
        description: "We want to understand your goals, your audience, and what makes your business unique. It's not about just building a website, but about crafting something that actually works for you.",
        image: process1
    },
    {
        id: 2,
        title: " Design and Prototyping",
        description: "Great design is not just about looking good, It's about usability, clarity, and connection-built mockups that will make you go, \"Yes, this is exactly what I imagined\".",
        image: process2,
    },
    {
        id: 3,
        title: "Development",
        description: "Once the design is set, our developers use modern tools to build a fast, secure, and user-friendly site for phone, tablet, or desktop.",
        image: process3,
    },
    {
        id: 4,
        title: "Testing and Quality Assurance",
        description: "We don't just build it and hope for the best. We test every link, button, and interaction. We will make sure your website works flawlessly for everyone, everywhere.",
        image: process4,
    },
    {
        id: 5,
        title: "Deployment and Launch",
        description: "Before launch, we fine-tune your website for speed, search engine optimization (SEO), and scalability, ensuring it delivers a seamless experience and ranks well in search engines.",
        image: process5,
    },
    {
        id: 6,
        title: " Maintenance and Updates",
        description: "We think that launch day is exciting, but it's really just the beginning. We stick around to make sure everything's running smoothly and to help with any tweaks or updates you might need.",
        image: process1,
    },
]

export default function ProcessNew() {
    return (
        <section id="process">
            <div className="process-container container1">
                <div className="title">
                    <h2>Our Process</h2>
                </div>
                <div className="process-grid">
                    {process.map((ele, i) => {
                        return (
                            <div className="process-grid-card">
                                <span className="si-no">{i+1}.</span>
                                <div className="process-image">
                                    <video src={ele.image} alt={ele.title} autoPlay loop muted />
                                </div>
                                <div className="process-content">
                                        <h1>{ele.title}</h1>
                                        <p>{ele.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}