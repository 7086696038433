import React, { useEffect, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion"
import "./Hero.css";

import video from "../../../Assets/Logo/web-development.mp4";
import whatsappIcon from "../../../Assets/Common/whatsapp.webp";
import mouse from "../../../Assets/Common/mouse-white.webp"
import clutch from "../../../Assets/Common/clutch-white.webp"

export default function Hero({ size }) {
  const textVariants = {
    initial: {
      x: -size*5,
      opacity: 0
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.1
      }
    },
    scrollButtonInitial: {
      opacity: 0
    },
    scrollButton: {
      opacity: 1,
      y: 10,
      transition: {
        duration: 2,
        repeat: Infinity
      }
    }
  } 

  const ref = useRef()
  const {scrollYProgress} = useScroll({
    target: ref,
    start: ["start start", "end start"]
  })

  const yBg = useTransform(scrollYProgress, [0, 1], [0, 1000])

  useEffect(() => {
    // Create a container for the Zoho SalesIQ widget
    const zohoWidgetContainer = document.createElement("div");
    zohoWidgetContainer.id = "zoho-chat-widget-container"; // Custom ID for styling

    // Add the Zoho SalesIQ script
    const zohoScript = document.createElement("script");
    zohoScript.innerHTML = `window.$zoho = window.$zoho || {};
      $zoho.salesiq = $zoho.salesiq || {ready:function(){}};
    `;

    const zohoWidgetScript = document.createElement("script");
    zohoWidgetScript.id = "zsiqscript";
    zohoWidgetScript.src = "https://salesiq.zohopublic.com/widget?wc=siqc58adfd6b9cd70e27454f5c565b0d0203eb6e3b923fbdb19d47681d002756393";
    zohoWidgetScript.defer = true;

    // Append scripts to the container
    zohoWidgetContainer.appendChild(zohoScript);
    zohoWidgetContainer.appendChild(zohoWidgetScript);

    // Add the container to the DOM
    document.body.appendChild(zohoWidgetContainer);

    return () => {
      // Cleanup: Remove the scripts and container when the component is unmounted
      if (zohoWidgetContainer) {
        document.body.removeChild(zohoWidgetContainer);
      }
    };
  }, []);

  return (
    <div className="hero">
      <div className="overlay"></div>
      <video src={video} autoPlay loop muted />
      <motion.div className="hero-content container1" variants = {textVariants} initial="initial" whileInView="animate" style={{ y:yBg}}>
        <motion.h1 variants = {textVariants}>BUILD YOUR ONLINE PRESENCE WITH<span className="color"> WEBSITES</span> THAT ARE EASY TO USE</motion.h1>
        <motion.div className="review-button">
          {/* <a href="https://clutch.co/profile/rabbit-carrot"> */}
          <div className="clutch-review">
                  <div className="left">
                    REVIEWED ON
                    <img src={clutch} alt="" />
                  </div>
                  <div className="right">
                  <div className="rating">
                      <span className="star">&#9733;</span>
                      <span className="star">&#9733;</span>
                      <span className="star">&#9733;</span>
                      <span className="star">&#9733;</span>
                      <span className="star">&#9733;</span>
                    </div>
                    5.0 RATING
                  </div>
                </div>
                {/* </a> */}
          <a href="#about-contact"><button variants = {textVariants} className="btn button-touch2">Let's Discuss
              <span className="line">
                  <span className="circle"></span>
              </span>
          </button></a>
        </motion.div>
      </motion.div>
      <motion.div className="scroll" variants = {textVariants} initial="scrollButtonInitial" animate="scrollButton">
        <img className="mouse" src={mouse} alt="" />
        <a href="#about-contact"><h1>Scroll to explore</h1></a>
      </motion.div>
      <a href="https://wa.me/+971542226464" className="whatsapp">
        <img src={whatsappIcon} alt="" className="whatsapp-icon" />
      </a>
    </div>
  );
}