import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
// import { ImEnlarge } from "react-icons/im";
import "./Portfolio.css"
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

// import play from "../../../Assets/Common/play-button.svg"

import web1 from "../../../Assets/Portfolio/web/web1.webp"
import web2 from "../../../Assets/Portfolio/web/web2.webp"
import web3 from "../../../Assets/Portfolio/web/web3.webp"
import web4 from "../../../Assets/Portfolio/web/web4.webp"
import web5 from "../../../Assets/Portfolio/web/web5.webp"
import web6 from "../../../Assets/Portfolio/web/web6.webp"
import web7 from "../../../Assets/Portfolio/web/web7.webp"
import web8 from "../../../Assets/Portfolio/web/web8.webp"
import web9 from "../../../Assets/Portfolio/web/web9.png"
import web10 from "../../../Assets/Portfolio/web/web10.png"
import web11 from "../../../Assets/Portfolio/web/web11.webp"
import web12 from "../../../Assets/Portfolio/web/web12.png"

const gallaryVariants = {
  initial: { y: 200, opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  exit: { y: -200, opacity: 0, transition: { duration: .5 } },
  // initial1: { x: -100, opacity: 0 },
  // animate1: { x: 0, opacity: 1, transition: { duration: 1, staggerChildren: 0.1 } },
  // exit1: { x: -100, opacity: 0, transition: { duration: .5 } },
};

export default function Portfolio() {
    // const [playingImage, setPlayingImage] = useState(null);

    // const handleImageClick = (imageUrl) => {
    //     setPlayingImage(imageUrl);
    //   };

  const images = [
    { src: web1 },
    { src: web2 },
    { src: web3 },
    { src: web4 },
    { src: web5 },
    { src: web6 },
    { src: web7 },
    { src: web8 },
    { src: web9 },
    { src: web10 },
    { src: web11 },
    { src: web12 },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  return (
    <section id="portfolio" className="portfolio">
      <h1 className="commonHeading"><span>Portfolio</span></h1>
      {/* <AnimatePresence> */}
        <div 
          // initial="initial"
          // whileInView="animate"
          // transition={{
          //     duration: 1,
          //     ease: "easeOut",
          //     delay: 0.2
          // }}
          // exit="exit"
          // viewport={{ once: false, amount: 0.1 }}
          // variants={gallaryVariants}
          className="portfolioContainer">
            {images.map((ele, index) => {
              return (
                <div key={index} className="image-container" onClick={() => openLightbox(index)}>
                  <img src={ele.src} alt={`Portfolio Item ${index + 1}`} />
                </div>
              )
            })}
          {/* <div className="image-container" onClick={() => handleImageClick(web1)}>
            <img src={web1} alt="Portfolio Item" />
          </div> */}
        </div>
      {/* </AnimatePresence> */}

      <div className="button-container content-center">
        <a href="#about-contact"><button className="btn button-touch2"> Explore More
          <span className="line">
              <span className="circle"></span>
          </span>
        </button></a>
      </div>

    {/* {playingImage && (
      <div className="video-overlay">
        <div className="video-player">
          <img src={playingImage} />
        </div>
        <img className="close-button1" alt="Close Button" onClick={() => setPlayingImage(null)} src={closeButton}/>
      </div>
    )} */}

    {isOpen && (
      <Lightbox
        slides={images}
        open={isOpen}
        index={currentIndex}
        close={() => setIsOpen(false)}
        plugins={[Fullscreen, Zoom]}
      />
    )}
  </section>
  )
}