import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import LazyLoad from "react-lazyload"
import { motion } from "framer-motion";

import "./CaseStudy.css"


import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import neom from "../../../Assets/CaseStudy/neom.webp"
// import snapchat from "../../../Assets/CaseStudy/snapchat.webp"
// import yamaha from "../../../Assets/CaseStudy/yamaha.webp"
// import australian from "../../../Assets/CaseStudy/australia.webp"

import thumbnail1 from "../../../Assets/CaseStudy/web3.webp"
import thumbnail2 from "../../../Assets/CaseStudy/web6.webp"
import thumbnail3 from "../../../Assets/CaseStudy/web8.webp"
import { useState } from "react";
import { FaRegCirclePlay } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

const CaseStudies = [
    {
        id: 1,
        title: "KILT",
        // logo: neom,
        description: "We developed a dynamic website for KILT, enhancing user experience with a sleek, responsive design, intuitive navigation, and fast performance. This platform elevated their online presence, improving customer engagement and conversion rates.",
        thumbnail: thumbnail1,
        // youtube: "https://youtu.be/sp6vU1az9gQ",
    },
    {
        id: 2,
        title: "DENTAL CORPORATION",
        // logo: snapchat,
        description: "We crafted a user-friendly website for Dental Corporation, featuring a modern, responsive design with easy appointment scheduling and clear service information. This improved patient engagement and streamlined the user experience.",
        thumbnail: thumbnail2,
        // youtube: "https://youtu.be/iDlKd1AwDYE",
    },
    {
        id: 3,
        title: "CASSIDY",
        // logo: yamaha,
        description: "We developed a sleek, responsive website for Cassidy, focusing on showcasing their services with intuitive navigation and modern design. This enhanced user interaction and brand visibility, driving higher engagement and conversions.",
        thumbnail: thumbnail3,
        // youtube: "https://youtu.be/bRbkI0eJ24M",
    },
]


export default function CaseStudy() {
    const textVarient = {
        initial: {
            y: 100,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 1,
                ease: "easeOut",
                staggerChildren: 0.3, // Stagger the children by 0.3s
            },
        },
      };
    
      const childVariants = {
          initial: { y: 50, opacity: 0 },
          animate: { y: 0, opacity: 1, transition: { duration: 0.6 } },
      };

    return (
        <section id="case-studies" className="caseStudy container">
            {/* <div className="heading-div">
                <h1>
                    <hr />Case Studies
                </h1>
            </div> */}
            <h1 className="commonHeading"><span>Case Studies</span></h1>
            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={1}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 50,
                modifier: 1,
                slideShadows: false, 
              }}
              pagination={{ el: '.swiper-pagination', clickable: true }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                clickable: true,
              }}
              modules={[EffectCoverflow, Pagination, Navigation]}
              className="swiper_container"
            >
                {CaseStudies.map((ele) => {
                    return (
                        <SwiperSlide key={ele.id}>
                            <div className='flexSB'>
                                <div className='left'>
                                    <img
                                        src={ele.thumbnail}
                                        alt={ele.title}
                                    />
                                </div>
                                <motion.div className='right'>
                                    <motion.div className="aboutHeading" initial="initial" whileInView="animate" variants={textVarient}>
                                        <motion.div variants={childVariants} className="top">
                                            {/* <img src={ele.logo} alt="2D Animation Company in Dubai" /> */}
                                            <h2>{ele.title}</h2>
                                        </motion.div>
                                        <motion.p variants={childVariants}>{ele.description}</motion.p>
                                    </motion.div>
                                </motion.div>
                            </div>
                        </SwiperSlide>
                    )
                })}
                <div className="slider-controler">
                <div className="swiper-button-prev slider-arrow">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="swiper-button-next slider-arrow">
                  <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <div className="swiper-pagination"></div>
              </div>
            </Swiper>
        </section>
    )
}