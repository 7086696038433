import React, { useEffect, useRef, useState } from 'react'
import "./UseCases.css"
import { AnimatePresence, motion } from "framer-motion"
import { FaRightLong, FaLeftLong } from "react-icons/fa6";

import image1 from "../../../Assets/UseCases/custom-website.webp"
import image2 from "../../../Assets/UseCases/e-commerce.webp"
import image3 from "../../../Assets/UseCases/web-app.webp"
import image4 from "../../../Assets/UseCases/wordpress.webp"
// import image5 from "../../../Assets/UseCases/landing.png"
// import image6 from "../../../Assets/UseCases/landing.png"

import video1 from "../../../Assets/UseCases/Culture.mp4";
import video2 from "../../../Assets/UseCases/Social-Ad.mp4";
import video3 from "../../../Assets/UseCases/Email-Marketing1.mp4" 
import video4 from "../../../Assets/UseCases/Home-Page.mp4"
// import video5 from "../../../Assets/UseCases/2.mp4"

import play from "../../../Assets/Common/play-button.svg"
import closeButton from "../../../Assets/Common/close-button1.svg"
// import ReactPlayer from 'react-player';

const items = [
    {
        id: 1,
        title: "Custom Website Development",
        img: image1,
        video: video1,
        description1: "Custom website development involves creating a fully personalized, scalable, and dynamic web solution designed to meet the specific requirements of your business.",
        description2: "Using video to promote culture allows Rabbit And Carrot to highlight what makes your brand unique, from its mission to the people behind it. Our videos engage audiences by authentically showcasing your company’s story, fostering stronger connections, and creating a memorable impression that builds trust and loyalty."
    },
    {
        id: 2,
        title: "E-Commerce Development",
        img: image2,
        video: video2,
        description1: "Custom e-commerce development delivers tailored online stores with advanced features, secure payments, and mobile responsiveness, enhancing user experiences, boosting sales, and helping businesses stand out in a competitive market.",
        description2: "Our Social Ad Video service at Rabbit And Carrot is designed to captivate your audience on social media platforms, delivering your message with impact and style. We create high-quality, engaging videos tailored for platforms like Instagram, Facebook, and TikTok to increase visibility, drive interaction, and boost conversions."

    },
    {   
        id: 3,
        title: "Web Application Development",
        img: image3,
        video: video3,
        description1: "Custom web application development delivers scalable, secure, and user-friendly solutions, streamlining processes, enhancing efficiency, and ensuring seamless experiences tailored to business needs across devices.",
        description2: "Our Email Marketing Video service brings your messages to life, helping you cut through inbox clutter and capture your audience’s attention. We craft personalized, high-quality videos that engage viewers, boost open rates, and drive action. Whether it's showcasing a product, sharing a story, or delivering a company update, our email videos are optimized for quick loading and mobile viewing, ensuring they reach your audience effectively."

    },
    {
        id: 4,
        title: "WordPress Development",
        img: image4,
        video: video4,
        description1: "WordPress development offers customizable, user-friendly websites with responsive designs, SEO optimization, and seamless plugin integration, enabling businesses to build scalable, engaging, and easily manageable digital platforms.",
        description2: "Our Home Page Videos are designed to create an unforgettable first impression, capturing your audience’s interest the moment they land on your website. These videos are optimized for fast loading and mobile responsiveness, ensuring a smooth viewing experience across all devices. Transform your home page into a dynamic and engaging experience with compelling video content."

    },
]

export default function UseCases({ size }) {
    const [playingVideo, setPlayingVideo] = useState(null);
    const [currentUseCase, setCurrentUseCase] = useState(items.find(ele => ele.video === playingVideo))

    const handleVideoClick = (videoUrl) => {
        setPlayingVideo(videoUrl)
    };

    // console.log(currentUseCase)

    useEffect(() => {
        setCurrentUseCase(items.find(ele => ele.video === playingVideo))
    }, [setCurrentUseCase, playingVideo])

    const containerRefs = useRef([]); // Array to store references to each container
    const [currentIndex, setCurrentIndex] = useState(0); // State to track the current container index

    const scrollToNextContainer = () => {
        if (currentIndex < containerRefs.current.length - 1) {
            const nextIndex = currentIndex + 1;
            containerRefs.current[nextIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
            setCurrentIndex(nextIndex); // Update the current index
        } else {
            // Loop back to the first container
            const firstIndex = 0;
            containerRefs.current[firstIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
            setCurrentIndex(firstIndex);
        }
    };

    return (
        <section id="use-cases" className="usecases">
            <h1 className="commonHeading">Use Cases</h1>
            <div className="scroll-button">
                <motion.button 
                    initial= {{
                        // opacity: 0,
                        x: 0
                    }}
                    animate={{
                        // opacity: 1,
                        x: 30,
                        transition: {
                            duration: 2,
                            repeat: Infinity
                        }
                    }}
                    onClick={scrollToNextContainer}>
                    Scroll For More <FaRightLong className="arrow"/>
                </motion.button>
            </div>
            <div className="usecases-main-container">
                {items.map((ele, i)=> {
                    return (
                        <div key={ele.id} className="usecases-container" ref={(el) => (containerRefs.current[i] = el)}>
                            <div className="top">
                                <motion.img 
                                    initial={{
                                        opacity: 0,
                                        y: -30
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                        y: 0
                                    }}
                                    transition={{
                                        duration: 1,
                                        ease: "easeOut",
                                        delay: 0.2
                                    }}
                                    viewport={{ once: false, amount: 0.5 }}
                                    src={ele.img} alt="2D Animation House" 
                                    // onClick={() => handleVideoClick(ele.video)}
                                    className="usecases-img"
                                />
                                {/* <div className="overlay">
                                    <div className="row">
                                        <p className="name">VIDEO PRODUCTION</p>
                                        <img className="play-button" src={play} alt=""/>
                                        <p className="company-name">KNORR</p>
                                    </div>
                                </div> */}
                            </div>
                            <motion.div
                                initial={{
                                    opacity: 0,
                                    y: 50
                                }}
                                whileInView={{
                                    opacity: 1,
                                    y: 0
                                }}
                                transition={{
                                    duration: 1,
                                    ease: "easeOut",
                                    delay: 0.2
                                }}
                                viewport={{ once: false, amount: 0.25 }}
                                className="bottom">
                                <h1>{ele.title}</h1>
                                <p>{ele.description1}</p>
                            </motion.div>
                        </div>
                    )
                })}
            </div>
            <AnimatePresence>
                {playingVideo && (
                    <motion.div 
                        className="video-overlay" 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="overlay"></div>
                        <motion.div 
                            className="video-player"
                            initial={{ scale: 0.5 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                            transition={{ duration: 0.5 }}
                        >
                            <video src={playingVideo} autoPlay loop muted/>
                            <div className="video-content">
                                <h1>{currentUseCase?.title.toUpperCase()}</h1>
                                <h3>WHY USE VIDEO TO PROMOTE {currentUseCase?.title.replace(/\bVideo\b/i, '').trim().toUpperCase()}?</h3>
                                <p>{currentUseCase?.description2}</p>
                            </div>
                        </motion.div>
                        <motion.img 
                            animate={{
                                scale: [1, 1.2, 1.2, 1],
                                rotate: [0, 90, 90, 0],
                                borderRadius: [ "50%"]
                            }}
                            transition={{
                                duration: 5,
                                ease: "easeInOut",
                                repeat: Infinity,
                                repeatDelay: 1
                            }}
                            whileTap={{ scale: 0.9}}
                            whileHover={{
                                scale: 1.2,
                                background: "#BE3030",
                                color: "white"
                            }}
                            drag
                            dragConstraints={{
                                top: -60,
                                right: 60,
                                bottom: 0,
                                left: -60
                            }}
                            className="close-button1" onClick={() => setPlayingVideo(null)} src={closeButton}/>
                    </motion.div>
                )}
            </AnimatePresence>

        </section>
    )
}