import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./Services.css";
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/splide/dist/css/splide.min.css';

import client1 from "../../../Assets/Clients/abudhabi-culture-tourism.png"
import client2 from "../../../Assets/Clients/abudhabi-police.png"
import client3 from "../../../Assets/Clients/abudhabi-sewage.png"
import client4 from "../../../Assets/Clients/agitha.png"
import client5 from "../../../Assets/Clients/australia-post.png"
import client6 from "../../../Assets/Clients/become.png"
import client7 from "../../../Assets/Clients/broccoli.png"
import client8 from "../../../Assets/Clients/canon.png"
import client9 from "../../../Assets/Clients/channel.png"
import client10 from "../../../Assets/Clients/dahua.png"
import client11 from "../../../Assets/Clients/emirates-nbd.png"
// import client12 from "../../../Assets/Clients/emirates.png"
import client13 from "../../../Assets/Clients/french-embassy.png"
import client14 from "../../../Assets/Clients/huawei.png"
import client15 from "../../../Assets/Clients/marhaba.png"
import client16 from "../../../Assets/Clients/mok.png"
// import client17 from "../../../Assets/Clients/mok.png"
import client18 from "../../../Assets/Clients/neom.png"
import client19 from "../../../Assets/Clients/pmi.png"
import client20 from "../../../Assets/Clients/reyl.png"
import client21 from "../../../Assets/Clients/rolex.png"
import client22 from "../../../Assets/Clients/rpm.png"
import client23 from "../../../Assets/Clients/saudi-aramco.png"
import client24 from "../../../Assets/Clients/shazam.png"
import client25 from "../../../Assets/Clients/siemens.png"
import client26 from "../../../Assets/Clients/snapchat.png"
import client27 from "../../../Assets/Clients/toshiba.png"
import client28 from "../../../Assets/Clients/yamaha.png"

const clients = [
  { img: client1, },
  { img: client2, },
  { img: client3, },
  { img: client4, },
  { img: client5, },
  { img: client6, },
  { img: client7, },
  { img: client8, },
  { img: client9, },
  { img: client10, },
  { img: client11, },
//   { img: client12, },
  { img: client13, },
  { img: client14, },
  { img: client15, },
  { img: client16, },
  // { img: client17 },
  { img: client18 },
  { img: client19 },
  { img: client21 },
  { img: client22 },
  { img: client23 },
  { img: client24 },
  { img: client25 },
  { img: client26 },
  { img: client27 },
  { img: client28 },
];


const serviceTypes = [
    {
        id: 1,
        title: "Front-End Development",
        description: "The creation of the website elements that users interact with is the main focus of front-end development. It involves working with technologies like HTML, CSS, and JavaScript to design and code the layout, graphics, and interactive elements. The objective? A smooth, adaptable, and eye-catching experience for each user. Our UI/UX design services help create interfaces that feel intuitive and engaging."
    },
    {
        id: 2,
        title: "Back-End Development",
        description: "Back-end development is in charge of the innermost functions of a website. It guarantees that everything functions properly, from servers and databases to application logic. Back-end developers provide the engine that processes data and powers functionality using languages like Python, PHP, or Node.js. With back-end development, we ensure that your website operates seamlessly behind the scenes."
    },
    {
        id: 3,
        title: "Full-Stack Development",
        description: "Why pick one when you can have it all? Full-stack development means we handle the front-end and back-end, giving you a complete, polished website that works perfectly from top to bottom. Whether it’s through development or custom coding, we’ve got you covered at every layer."
    },
    {
        id: 4,
        title: "E-commerce Development",
        description: "Your online store, built for sales. We make sure it’s fast, secure, and easy for your customers to shop-whether they’re browsing or checking out. Our e-commerce solutions ensure your store offers a seamless shopping experience from start to finish."
    },
    {
        id: 5,
        title: "CMS Development",
        description: "Want to update your site without calling a developer every time? A CMS lets you do that. We’ll build you a system that’s simple to use so you can manage your site like a pro. With CMS development, we create platforms that empower you to make quick, easy updates."
    },
    {
        id: 6,
        title: "Web Application Development",
        description: "Need something more than just a website? We create powerful web apps that do it all-whether it’s a booking system, a productivity tool, or something completely unique to your business. Our web application development is tailored to meet the specific needs of your business."
    },
    {
        id: 7,
        title: "Custom Web Development",
        description: "Got an idea that doesn’t fit into a template? We specialize in building custom solutions tailored exactly to what you need. Your vision, your way. From custom website design to complex functionality, we ensure your site is as unique as your business."
    },
    {
        id: 8,
        title: "API Development and Integration",
        description: "Want your site to play nice with other tools? We’ll connect it to third-party systems-like payment gateways or social platforms-so everything works together seamlessly. Our API development and integration services ensure your site integrates perfectly with external systems."
    },
    {
        id: 9,
        title: "Maintenance and Support",
        description: "We don’t just build your site and disappear. We’re here to keep it running, fix any issues, and make updates whenever you need them. Our website maintenance services ensure your site stays fresh, functional, and up-to-date."
    },
    {
        id: 10,
        title: "Web Performance Optimization",
        description: "Nobody likes a slow website. We’ll make sure yours is lightning-fast, responsive, and smooth so your visitors stick around and enjoy the ride. Our SEO web optimization ensures that your site is optimized for both speed and search engine visibility."
    } 
];
    


export default function Services({size}) {

    const [currentIndex, setCurrentIndex] = useState(0);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentIndex((prevIndex) => (prevIndex + 1) % service.serviceTypes.length);
    //     }, 2000); // Change every 2 seconds
    //     return () => clearInterval(interval); // Cleanup on unmount
    // }, []);

    const textVariants = {
        initial: {
            y: 200,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 1,
                ease: "easeOut",
                staggerChildren: 0.3, // Stagger the children by 0.3s
            },
        },
    };
    
    const childVariants = {
        initial: { y: 100, opacity: 0 },
        animate: { y: 0, opacity: 1, transition: { duration: 0.6 } },
    };  

    const settings = {
        // customPaging: function(i) {
        //   return (
        //     <a>
        //       <img 
        //         src={clientImages[i]} 
        //         alt={`Client ${i + 1}`} 
        //         style={{ width: "20px", height: "20px", objectFit: "cover" }} 
        //     />
        //     </a>
        //   );
        // },
        dots: false,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (current, next) => setCurrentIndex(next)
      };

      const settings1 = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                dots: true
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 2
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: false
            }
            }
        ]
      };

      useEffect(() => {
        const splide = new Splide('.splide', {
            type: 'loop',
            drag: 'free',
            focus: 'center',
            perPage: 6,
            autoScroll: {
                speed: 1,
            },
            pagination: false,
            arrows: false,
            breakpoints: {
                1024: {
                  perPage: 4,
                },
                600: {
                  perPage: 3,
                },
                480: {
                    perPage: 2,
                },
              },
        });

        splide.mount({ AutoScroll });
    }, []);

    return (
        <section id="services" className="services">
            <div className="overlay"></div>
            <div className="services-content">
                <motion.div className="top container1" variants = {textVariants} initial="initial" whileInView="animate">
                    {/* <motion.div className="header-container"> */}
                        <motion.h1 variants={childVariants} className="heading">Web Development provides 
                            <motion.div variants={childVariants} className="service-types">
                                <Slider {...settings}>
                                    {serviceTypes.map((ele) => {
                                        return (
                                            <div className="types" key={ele.id}>
                                                <h1>{ele.title}</h1>
                                            </div>
                                        )
                                    })}
                                </Slider>
                                <motion.p variants={childVariants}>that visually convey engaging narratives.</motion.p>
                            </motion.div> 
                        </motion.h1>
                    {/* </motion.div> */}
                    {/* {serviceTypes.map(ele=> {
                        if(ele.id === currentIndex) {
                            return ( */}
                                <motion.p variants={childVariants}>{serviceTypes[currentIndex].description}</motion.p>
                    {/* //         )
                    //     }
                    // })} */}
                    <motion.a variants={childVariants} href="#about-contact"><button className="btn">
                        Book A Free Consultation
                    </button></motion.a>
                </motion.div>
                <div className="bottom-overlay"></div>
                <div className="splide">
                    <div className="splide__track">
                        <ul className="splide__list">
                        {clients.map((ele, index) => (
                            <li className="splide__slide" key={index}>
                            <img src={ele.img} alt={`Client ${index + 1}`} className="slider-image" />
                            </li>
                        ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}